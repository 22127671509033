import { useCreate } from '@brainstud/academy-api/Factories/useCreate';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { UseMutationOptions } from '@tanstack/react-query';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';

export interface IVerifyEmailSignatureProps {
  account: string;
  signature: string;
  expires_at: string;
}

type Settings = UseMutationOptions<
  DataDocument<undefined>,
  ErrorDocument,
  IVerifyEmailSignatureProps
>;

export function useVerifyEmailSignature(settings?: Settings) {
  const context = useApi();
  const {
    parameters: { account, signature, expires_at },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.verifyEmailAddress',
      baseUri: '/v1/account_actions/verify_email_address',
      uri: `/${account}/${signature}/${expires_at}`,
    },
    context
  );
  return useCreate<undefined, IVerifyEmailSignatureProps>(request, settings);
}
